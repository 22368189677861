//import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./amplifyConfig.js";
import "./i18n.js";
import "./index.css";

// we must import the app AFTER amplifyConfig
import App from "./App.js";

const root = createRoot(globalThis.document.getElementById("root"));
root.render(
    //<StrictMode>
    <App />
    //</StrictMode>
);
