import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Link, useLoaderData } from "react-router-dom";

const Sidebar = ({ isDrawerOpen, setIsDrawerOpen, modalDrawer, width }) => {
    const accessData = useLoaderData();
    const accessOpenDataApi = accessData.hasAccess;
    const accessTerms = accessData.api_agreement_accepted;
    return (
        <>
            <Drawer
                sx={{
                    width,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width,
                        boxSizing: "border-box",
                    },
                }}
                anchor="left"
                variant={modalDrawer ? "temporary" : "persistent"}
                open={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
            >
                <Box p={2} textAlign="center" role="presentation">
                    <Box
                        component="img"
                        sx={{
                            width: 180,
                        }}
                        alt="Wiseconn Logo"
                        src="img/wiseconn.png"
                    />
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/" onClick={() => setIsDrawerOpen(false)}>
                                <ListItemIcon>
                                    <HomeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Versions" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/documentation-api" onClick={() => setIsDrawerOpen(false)}>
                                <ListItemIcon>
                                    <MenuBookIcon />
                                </ListItemIcon>
                                <ListItemText primary="Documentation" />
                            </ListItemButton>
                        </ListItem>
                        {accessOpenDataApi ?
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to="/documentation-anonymus" onClick={() => setIsDrawerOpen(false)}>
                                    <ListItemIcon>
                                        <InsertDriveFileIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Open Data Documentation" />
                                </ListItemButton>
                            </ListItem> : null}
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/keystable" onClick={() => setIsDrawerOpen(false)}>
                                <ListItemIcon>
                                    <CloudDownloadIcon />
                                </ListItemIcon>
                                <ListItemText primary="Api Keys" />
                            </ListItemButton>
                        </ListItem>
                        {accessTerms ?
                            <ListItem disablePadding>
                                <ListItemButton component={Link} to="/terms-conditions" onClick={() => setIsDrawerOpen(false)}>
                                    <ListItemIcon>
                                        <ListAltIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Terms & Conditions" />
                                </ListItemButton>
                            </ListItem> : null}
                    </List>

                </Box>
            </Drawer>
        </>
    );
};

export default Sidebar;
