import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Sidebar from "../Sidebar/Sidebar";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";

import { AuthenticatorContext } from "../../contexts/AuthenticatorContext";
import { useVersionsUpdateContext, useVersionsContext } from "../../contexts/VersionsContext";
import { styled } from "@mui/material";
import { LayoutContext } from "../../layout/Layout";

const StyledAppBar = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== "open" && prop !== "drawerWidth",
})(({ theme, open, drawerWidth }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export default function SearchAppBar({ isDrawerOpen, modalDrawer, drawerWidth }) {
    const { signOut, userInfo } = useContext(AuthenticatorContext);
    const location = useLocation();
    const { setIsDrawerOpen } = useContext(LayoutContext);

    const [DocumentationVersionsOptions, OpenDataDocumentationVersionsOptions] = useVersionsContext();
    const [, setSelectedDocumentationVersion, , setSelectedOpenDataDocumentationVersion] = useVersionsUpdateContext();
    const defaultIndex = 0;
    const [documentationIndex, setDocumentationIndex] = useState(defaultIndex);
    const [openDataDocumentationIndex, setOpenDataDocumentationIndex] = useState(defaultIndex);

    const searchParams = new URLSearchParams(globalThis.document.location.search);
    const defaultVer = DocumentationVersionsOptions.find(v => {
        return v.value.includes(`-${searchParams.get("version")}-`);
    });
    useEffect(() => {
        if (defaultVer) {
            setDocumentationIndex(DocumentationVersionsOptions.indexOf(defaultVer) ?? 0);
            setSelectedDocumentationVersion(defaultVer);
        }
    }, [defaultVer]);

    const handleDocumentationChange = (event) => {
        setDocumentationIndex(event.target.value);
        setSelectedDocumentationVersion(DocumentationVersionsOptions[event.target.value]);
    };
    const handleOpenDataDocumentationChange = (event) => {

        setOpenDataDocumentationIndex(event.target.value);
        setSelectedOpenDataDocumentationVersion(OpenDataDocumentationVersionsOptions[event.target.value]);
    };

    const navigate = useNavigate();
    function logout() {
        signOut();
        navigate("/");
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <StyledAppBar position="fixed" elevation={0} open={isDrawerOpen ? !modalDrawer : null} drawerWidth={drawerWidth}>
                <Toolbar sx={{ backgroundColor: "#263238" }}>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        sx={{ mr: 2 }}
                        onClick={() => setIsDrawerOpen(current => !current)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
                    >
                        Integrator Site
                    </Typography>
                    {location.pathname == "/documentation-api" ?
                        <FormControl sx={{ m: 1, minWidth: 130}}>
                            <Select
                                value={documentationIndex}
                                onChange={handleDocumentationChange}
                                sx={{backgroundColor: "white"}}
                            >
                                {DocumentationVersionsOptions.map((version, index) => {
                                    return (
                                        <MenuItem value={index} key={index}>
                                            {version.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl> : null}
                    {location.pathname == "/documentation-anonymus" ?
                        <FormControl sx={{ m: 1, minWidth: 130}}>
                            <Select
                                value={openDataDocumentationIndex}
                                onChange={handleOpenDataDocumentationChange}
                                sx={{backgroundColor: "white"}}
                            >
                                {OpenDataDocumentationVersionsOptions.map((version, index) => {
                                    return (
                                        <MenuItem value={index} key={index}>
                                            {version.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl> : null}
                    <Box sx={{display:"flex", gap:2, alignItems:"center", paddingLeft:2}}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{display: { xs: "none", sm: "block" }}}
                        >
                            {`${userInfo.name} ${userInfo.family_name}`}
                        </Typography>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={logout}
                        >
                            <LogoutIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </StyledAppBar>
            <Sidebar isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} modalDrawer={modalDrawer} width={drawerWidth} />
        </Box>
    );
}
