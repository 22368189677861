import { RedocStandalone } from "redoc";
import { useVersionsUpdateContext } from "../../contexts/VersionsContext";
import { Navigate, useRouteLoaderData } from "react-router-dom";
const option = {
    nativeScrollbars: true,
    theme: { colors: { primary: { main: "#dd5522" } } },
    scrollYOffset: 72,
};


export default function OpenDataDocumentation() {
    const [, , selectedOpenDataDocumentationVersion] = useVersionsUpdateContext();
    const accessData = useRouteLoaderData("root");
    const accessOpenDataApi = accessData.hasAccess;
    return (
        <div key={selectedOpenDataDocumentationVersion.label}>
            {accessOpenDataApi ?
                <RedocStandalone
                    key={selectedOpenDataDocumentationVersion.label}
                    specUrl={selectedOpenDataDocumentationVersion.value}
                    options={option}
                />: <Navigate to="/" />}
        </div>
    );
}
