import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider, defer } from "react-router-dom";
import { RestAPI } from "@aws-amplify/api-rest";

import Layout from "./layout/Layout.jsx";
import "./styles/layout/layout.scss";
import "./styles/pages/documents.scss";
import "./styles/pages/login.scss";

import Login from "./pages/Login/Login.js";
import withAuthenticator from "./hoc/withAuthenticator.js";

import VersionsProvider from "./contexts/VersionsContext";
import Main from "./pages/Main/Main.js";
import Documentation from "./pages/Documentation/Documentation.js";
import OpenDataDocumentation from "./pages/OpenDataDocumentation/OpenDataDocumentation.js";
import ApiKeys from "./pages/ApiKeys/ApiKeys.js";
import { TermsAndConditions } from "./pages/TermsAndConditions/TermsAndConditions.js";
import { Backdrop, CircularProgress } from "@mui/material";

const initialdelayms = 100;
const delayms = 2500;

const getRouter = () => createBrowserRouter([
    {
        element: <Layout />,
        loader: async () => {
            const apiPromise = Promise.all([
                RestAPI.get("apiApplication", "/check-api-access"),
                new Promise(resolve => setTimeout(() => resolve(), initialdelayms))
            ]).then(([apiPromiseRes]) => apiPromiseRes);
            return (apiPromise);
        },
        id: "root",
        /* https://reactrouter.com/en/main/route/loader */
        children: [
            {
                path: "/",
                element: <Main />,
                children: []
            },
            {
                path: "/documentation-api",
                element: <Documentation />,
                children: []
            },
            {
                path: "/documentation-anonymus",
                element: <OpenDataDocumentation />,
                children: []
            },
            {
                path: "/terms-conditions",
                element: <TermsAndConditions />,
                children: []
            },
            {
                path: "/keystable",
                loader: async () => {
                    const delay = new Promise((resolve) => setTimeout(resolve, delayms));
                    const apiPromise = RestAPI.get("apiApplication", "/api-keys");
                    return defer({
                        keys: Promise.all([apiPromise, delay]).then(([apiResult]) => apiResult)
                    });
                },
                element: <ApiKeys />,
                children: []
            }
        ]
    }
]);

const Loading = ({ hide }) => {
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={!hide}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

function App() {
    // loader functions will be called inmediatly when createBrowserRouter is called, if the route is correct
    // to prevent failing because we have no auth-data, defer the router creation to the mounting of the app component
    const [routerInfo, setRouterInfo] = useState({ router: null, idx: 0 });

    useEffect(() => {
        const router = getRouter();
        setRouterInfo((ri) => ({ router, idx: (ri.idx + 1) }));

        // we need to clear the memory of the previous router
        return () => router.dispose();
    }, []);

    if (!routerInfo.router) return null;
    return (
        <VersionsProvider>
            <RouterProvider
                key={routerInfo.idx} router={routerInfo.router}
                fallbackElement={<Loading />}
            />
        </VersionsProvider>
    );
}

export default withAuthenticator(App, Login, Loading);
//export default App;
