import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Await, useLoaderData, useAsyncValue } from "react-router-dom";
import { Suspense, useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Skeleton from "@mui/material/Skeleton";
import Alert from "@mui/material/Alert";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3E4A57",
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const renderPermissions = (code) => {
    if (code.includes("166")){
        return "Full Control";
    }
    else if (code.includes("95")){
        return "Control Access";
    }
    else if (code.includes("94")){
        return "Read Access";
    }
};
const ApiKeysTable = () => {
    const resolvedKeys = useAsyncValue().message;
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    useEffect(() => {
        handleChange();
    }, []);
    return (
        <Box>
            <Collapse in={checked}>
                <Table aria-label="customized table">
                    <TableBody>
                        {resolvedKeys.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.apikey}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.cuenta}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.campo}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {renderPermissions(row.permisos)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>
        </Box>
    );
};
/* const ApiKeysTable = () => {
    const resolvedKeys = useAsyncValue().message;
    console.log(useAsyncValue());
    if (resolvedKeys.length == 0) {
        return;
    }
    else if (resolvedKeys.length == 1) {
        const firstKey = resolvedKeys.shift();
        return (
            <Table aria-label="customized table">
                <TableBody>
                    <StyledTableRow key={firstKey.apikey}>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.apikey}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.cuenta}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.campo}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {renderPermissions(firstKey.permisos)}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        );
    }
    const firstKey = resolvedKeys.shift();
    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };
    React.useEffect(() => {
        handleChange();
    }, []);

    return (
        <Box>
            <Table aria-label="customized table">
                <TableBody>
                    <StyledTableRow key={100}>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.apikey}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.cuenta}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {firstKey.campo}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {renderPermissions(firstKey.permisos)}
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
            <Collapse in={checked}>
                <Table aria-label="customized table">
                    <TableBody>
                        {resolvedKeys.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.apikey}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.cuenta}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {row.campo}
                                </StyledTableCell>
                                <StyledTableCell sx={{width: "25%"}}>
                                    {renderPermissions(row.permisos)}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse>
        </Box>
    );
}; */

const TableLoading = () => {
    return (
        <Table aria-label="customized table">
            <TableBody>
                <StyledTableRow>
                    <StyledTableCell sx={{width: "25%"}}>
                        <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell sx={{width: "25%"}}>
                        <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell sx={{width: "25%"}}>
                        <Skeleton animation="wave" />
                    </StyledTableCell>
                    <StyledTableCell sx={{width: "25%"}}>
                        <Skeleton animation="wave" />
                    </StyledTableCell>
                </StyledTableRow>
            </TableBody>
        </Table>
    );
};

const TableErrorLoading = () => {
    return (
        <Alert severity="error">
            Error accessing API Keys
        </Alert>
    );
};

export default function ApiKeys() {
    const deferData = useLoaderData();
    return (
        <Box sx={{p: 2}}>
            <Typography
                noWrap
                component="div"
                sx={{ textAlign:"center", p:1, fontWeight: "bold", fontSize: "28px", color: "#3E4A57" }}
            >
                Integrator API Keys
            </Typography>
            <Table aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell sx={{width: "25%"}}>
                            Api keys
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            Account
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            Farm
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            Permissions
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
            </Table>
            <TableContainer component={Paper}>
                <Suspense fallback={<TableLoading />} >
                    <Await resolve={deferData.keys} errorElement={<TableErrorLoading />}>
                        <ApiKeysTable />
                    </Await>
                </Suspense>
            </TableContainer>
        </Box>
    );
}
