import React from "react";
import apiVersions from "../resources/apiVersions.json";

const DocumentationVersionsOptions = apiVersions.DocumentationVersionsOptions.map((versions) => {
    const newDocVersionsOptions = Object.assign({}, versions);

    newDocVersionsOptions.value = `${process.env.PUBLIC_URL}/versions/documentation/wiseconn-api-wiseconn-${versions.value}-swagger.json`;
    newDocVersionsOptions.label = versions.value + (versions.latest ? " (current)" : "");
    return newDocVersionsOptions;
});

const OpenDataDocumentationVersionsOptions = apiVersions.OpenDataDocumentationVersionsOptions.map((versions) => {
    const newOpenDataDocVersionsOptions = Object.assign({}, versions);

    newOpenDataDocVersionsOptions.value = `${process.env.PUBLIC_URL}/versions/openDataDocumentation/wiseconn-Api_Anonima-${versions.value}-swagger.json`;
    newOpenDataDocVersionsOptions.label = versions.value + (versions.latest ? " (current)" : "");
    return newOpenDataDocVersionsOptions;
});

const VersionsContext = React.createContext();
const VersionsUpdateContext = React.createContext();

export const useVersionsContext = () => {
    return React.useContext(VersionsContext);
};
export const useVersionsUpdateContext = () => {
    return React.useContext(VersionsUpdateContext);
};

const VersionsProvider = ({ children }) => {
    const searchParams = new URLSearchParams(globalThis.document.location.search);
    const defaultVer = DocumentationVersionsOptions.find(v => {
        return v.value.includes(`-${searchParams.get("version")}-`);
    });

    const versionsOptions = React.useMemo(() => ([DocumentationVersionsOptions, OpenDataDocumentationVersionsOptions]), []);
    const [selectedDocumentationVersion, setSelectedDocumentationVersion] = React.useState(defaultVer ?? DocumentationVersionsOptions[0]);
    const [selectedOpenDataDocumentationVersion, setSelectedOpenDataDocumentationVersion] = React.useState(OpenDataDocumentationVersionsOptions[0]);
    const updateSelected = React.useMemo(() => ([selectedDocumentationVersion, setSelectedDocumentationVersion, selectedOpenDataDocumentationVersion, setSelectedOpenDataDocumentationVersion]), [selectedDocumentationVersion, selectedOpenDataDocumentationVersion, defaultVer]);
    return (
        <VersionsContext.Provider value={versionsOptions}>
            <VersionsUpdateContext.Provider value={updateSelected}>
                {children}
            </VersionsUpdateContext.Provider>
        </VersionsContext.Provider>
    );
};

export default VersionsProvider;
