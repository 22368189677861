import { Fragment, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import apiVersions from "../../resources/apiVersions.json";
import versionsInfo from "../../resources/availableVersionsInfo.json";
import { Link } from "react-router-dom";
import { LayoutContext } from "../../layout/Layout";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3E4A57",
        color: theme.palette.common.white,
        fontWeight: "bold"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type": {
        backgroundColor: theme.palette.action.hover,
    },
}));

const VersionsTable = ({ setIsDrawerOpen }) => {
    const resolvedInfo = useMemo(() => {
        const baseVersionInfo = apiVersions.DocumentationVersionsOptions.reduce((res, v) => {
            res[v.value] = v;
            return res;
        }, {});

        return versionsInfo.filter(v=>baseVersionInfo[v.value] || baseVersionInfo[v.docVersion]).map(v=>{
            const tableInfo = { ...v };

            tableInfo.latest = (baseVersionInfo[v.value] || baseVersionInfo[v.docVersion]).latest;
            return tableInfo;
        });
    }, []);

    return (
        <Table aria-label="customized table">
            <TableBody>
                {resolvedInfo.map(i => (
                    <StyledTableRow key={i.value}>
                        <StyledTableCell sx={{width: "10%"}}>
                            <Link to={`/documentation-api?version=${i.docVersion || i.value}`} component="button" onClick={() => setIsDrawerOpen(false)}>
                                {i.value}
                            </Link>
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "20%"}}>
                            {i.latest ? "https://api.wiseconn.com/" : null}
                            {i.latest ? <br /> : null}
                            {`https://api.wiseconn.com/${i.value.replaceAll(".", "_")}`}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "15%"}}>
                            {i.release}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "15%"}}>
                            {i.eol}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "15%"}}>
                            {i.deprecation || "TBD"}
                        </StyledTableCell>
                        <StyledTableCell sx={{width: "25%"}}>
                            {i.notes.map((n, idx) => (
                                <Fragment key={n}>
                                    {idx ? <br /> : null}
                                    {n}
                                </Fragment>
                            ))}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default function Main() {
    const { t } = useTranslation();
    const { setIsDrawerOpen } = useContext(LayoutContext);

    return (
        <>
            <Box sx={{p: 2}}>
                <Typography
                    noWrap
                    component="div"
                    sx={{ textAlign:"center", p:1, fontWeight: "bold", fontSize: "28px", color: "#3E4A57" }}
                >
                    {t("versionsTable.title")}
                </Typography>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{width: "10%"}}>
                                {t("versionsTable.version")}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "20%"}}>
                                {t("versionsTable.path")}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "15%"}}>
                                {t("versionsTable.releaseDate")}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "15%"}}>
                                {t("versionsTable.EOL")}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "15%"}}>
                                {t("versionsTable.deprecated")}
                            </StyledTableCell>
                            <StyledTableCell sx={{width: "25%"}}>
                                {t("versionsTable.notes")}
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <TableContainer component={Paper}>
                    <VersionsTable setIsDrawerOpen={setIsDrawerOpen} />
                </TableContainer>
            </Box>
            <Box marginX={10}>
                <Typography>
                    The previous domain (apiv2.wiseconn.com) will be still working with the 2.9.1 version. We recommend changing the domain in your implementation to work with the latest version.
                </Typography>
            </Box>
        </>
    );
}
