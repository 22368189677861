import { RedocStandalone } from "redoc";
import { useVersionsUpdateContext } from "../../contexts/VersionsContext";
const option = {
    nativeScrollbars: true,
    theme: { colors: { primary: { main: "#dd5522" } } },
    scrollYOffset: 72,
};

export default function Documents() {
    const [selectedDocumentationVersion] = useVersionsUpdateContext();
    return (
        <div key={selectedDocumentationVersion.label}>
            <RedocStandalone
                key={selectedDocumentationVersion.label}
                specUrl={selectedDocumentationVersion.value}
                options={option}
            />
        </div>
    );
}
