import { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import { useLoaderData } from "react-router-dom";
import { Terms } from "../pages/Terms/Terms";
import { styled } from "@mui/material";

export const LayoutContext = createContext({});

const DRAWER_WIDTH = 250;
const MAIN_PADDING = 1;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(MAIN_PADDING),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open && {
            transition: theme.transitions.create("margin", {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${DRAWER_WIDTH}px`,
        }),
    }),
);

const Layout = () => {
    const accessData = useLoaderData();
    const location = useLocation();
    const acceptedAgreement = accessData.api_agreement_accepted;

    const shouldBeModalDrawer = location.pathname.length > 1;
    const [modalDrawer, setModalDrawer] = useState(shouldBeModalDrawer);
    const [isDrawerOpen, setIsDrawerOpen] = useState(!shouldBeModalDrawer);
    useEffect(() => {
        const delay = 160;
        const to = setTimeout(() => {
            setModalDrawer(shouldBeModalDrawer);
        }, delay);

        () => clearTimeout(to);
    }, [shouldBeModalDrawer]);

    useEffect(() => {
        if (!modalDrawer) setIsDrawerOpen(true);
    }, [modalDrawer]);

    const value = useMemo(() => ({ setIsDrawerOpen }), []);
    return (
        <div>
            {acceptedAgreement === null || acceptedAgreement ?
                <div>
                    <LayoutContext.Provider value={value}>
                        <Navbar isDrawerOpen={isDrawerOpen} modalDrawer={modalDrawer} drawerWidth={DRAWER_WIDTH} />
                        <Main open={isDrawerOpen ? !modalDrawer : null}>
                            <Outlet />
                        </Main>
                    </LayoutContext.Provider>
                </div> : <Terms />}
        </div>
    );
};

export default Layout;
