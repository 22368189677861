import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { RestAPI } from "@aws-amplify/api-rest";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

export const Terms = () => {

    const [acceptTerms, setacceptTerms] = React.useState(false);
    const navigate = useNavigate();
    const confirmTerms = async () => {
        try {
            const response = await RestAPI.post("apiApplication", "/check-api-access");
            console.log(response);
            navigate("/");
        } catch (e) {
            console.error(e);
        }
    };
    const handleChange = (event) => {
        setacceptTerms(event.target.checked);
    };
    return (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" elevation={4}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: 250,
                        }}
                        alt="Wiseconn Logo"
                        src="img/wiseconn.png"
                        p={2}
                    />
                    <Typography component="h1" variant="h6" sx={{ color: "#757575" }}>
                        Terms and conditions
                    </Typography>
                    <Box component="form" noValidate sx={{ mt: 2 }}>
                        <Box sx={{
                            overflow: "auto",
                            height: "250px",
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px"
                        }}
                        >
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                COMPANY API LICENSE AGREEMENT
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                BY CLICKING ON THE “ACCEPT” OR “SUBMIT” BUTTON, YOU OR THE ENTITY
                                THAT YOU REPRESENT (“YOU,” “YOUR,” “YOURS” OR
                                “LICENSEE”) ARE UNCONDITIONALLY CONSENTING TO BE BOUND BY, ARE BECOMING A
                                PARTY TO THIS COMPANY API LICENSE AGREEMENT (THE “AGREEMENT”), AND ARE
                                HEREBY REPRESENTING AND WARRANTING THAT YOU ARE AUTHORIZED TO BIND LICENSEE. YOUR
                                DOWNLOAD OR CONTINUED USE OF ANY PORTION OF THE API (AS DEFINED BELOW) SHALL ALSO
                                CONSTITUTE ASSENT TO THE TERMS OF THIS AGREEMENT. IF YOU DO NOT UNCONDITIONALLY AGREE TO
                                ALL OF THE TERMS OF THIS AGREEMENT, CLICK THE “DECLINE” BUTTON AND YOU WILL
                                HAVE NO RIGHT TO USE THE API. IF THESE TERMS ARE CONSIDERED AN OFFER, ACCEPTANCE IS
                                EXPRESSLY LIMITED TO THESE TERMS TO THE EXCLUSION OF ALL OTHER
                                TERMS.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                1. License Grant.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                Subject to full compliance with the terms of this Agreement, WiseConn Engineering, Inc.
                                (“we,” “us” “our” or “Company”) hereby
                                grants you a limited, personal, non-sublicensable, non-transferable, nonexclusive
                                license to use our DropControl application programming interface and related information
                                and documentation (available at https://developers.wiseconn.com/)
                                and subject to updates by the Company from time to time, the
                                “Documentation”) we may provide (collectively, the “API”), but
                                only for the purpose of (a) allowing Licensee’s software applications and
                                processes (each an “App”) used by a current, mutual customer of the Company
                                and Licensee who is in good standing (“Customer”) to communicate with
                                Company’s proprietary DropControl service (the “Service”), (b) to
                                allow the App to make queries to the API for purposes of receiving Customer’s data
                                (the “Content”) that is hosted on the Service, and to write data to
                                Customer’s account and/or generate irrigation schedules or commands for
                                Customer’s farm via the Service, and (c) for other purposes authorized by us in
                                writing. Licensee hereby acknowledges and agrees that all access to and use of
                                Customer’s data is subject to Customer’s consent, given or withdrawn by
                                Customer at any time in its sole discretion.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                2. Restrictions; Ownership.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                You shall not (and shall not authorize or encourage any third party to), directly or
                                indirectly: (i) rent, lease, loan, sell, sublicense, assign, or otherwise transfer any
                                rights in or to the API; (ii) clone the API, or use the API to build an application
                                programming interface, application or product that is competitive with the Service or
                                any other Company product or service; (iii) remove any proprietary notices from the API
                                (or any portion thereof); (iv) decompile, reverse engineer, disassemble, or derive the
                                source code, underlying ideas, concepts or algorithms of the API or Service (except as
                                and only to the extent the foregoing restrictions are expressly prohibited by applicable
                                statutory law); (v) modify or create derivative works of the API or Service; or cause,
                                encourage or permit any third party to engage in any of the foregoing proscribed acts;
                                or (vi) use the API in a manner other than expressly authorized in the Documentation.
                                Company shall own all right, title, and interest (and all intellectual property rights)
                                in and to the API and Service, including any copies and derivative works thereof. No
                                rights or licenses are granted except as expressly and unambiguously set forth
                                herein.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                3. Relationship of the Parties.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                The parties hereby agree that Licensee is not an agent, partner or joint venturer of
                                Company and shall not make any representations to Customer or any other party to the
                                contrary. Licensee shall not make any statements implying that implying that any
                                Services or data provided or modified by Licensee are in any way endorsed by
                                WiseConn.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                4. Confidentiality.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                You agree not to disclose (or allow access to) the API or the Content (or any
                                information derived therefrom) to any third party (other than the Customer) and will
                                limit access to the API and the Content (and any derived information) to your employees
                                who are developing the App(s). In support of this obligation, you will apply at least
                                the same security that you use to protect your own most confidential
                                information.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                5. Compatibility.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                Without limiting any other condition on the license granted above, each App must (a)
                                maintain 100% compatibility with the API and the Service (including changes provided to
                                you by Company, which shall be implemented in each App promptly thereafter), and (b)
                                support a minimum of TLS 1.0 encryption, or any future encryption or security standards
                                that the Company adopts, as set forth in the Documentation. If any App uses, implements
                                or relies upon any version of the API other than the most currently generally available
                                version, you acknowledge and agree that such App may not be able to communicate with the
                                Service. You agree not to modify, extend, subset or superset the API. You understand
                                that we may cease support of any prior versions or releases of the
                                API.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                6. API Updates.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                Company has no obligation to provide any such upgrades, patches, enhancements, fixes or
                                any other support for the API. However, if Company provides you with any upgrades,
                                patches, enhancements or fixes for the API, then all such items will become part of the
                                API, respectively, and subject to this Agreement.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                7. Usage and Monitoring.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                It is Licensee’s responsibility to ensure it does not exceed the API rate usage
                                limitations set forth in the Documentation. Company reserves the right to limit the
                                number of API calls that Licensee will be permitted to make in connection with the API
                                during any given period. Exceeding the API limit repeatedly may result in throttling or
                                the termination of this Agreement by Company. Licensee also agrees to provide Company
                                access to the App and/or other materials related to Licensee’s use of the API, the
                                Service and the Content in order to verify compliance with this Agreement. Licensee
                                agrees that Company may crawl or otherwise electronically monitor or review the App and
                                Licensee’s use of the API, the Service and the Content and Licensee shall not
                                block or interfere with such efforts by Company. Licensee’s failure to reasonably
                                comply with Company’s efforts to audit Licensee’s compliance with this
                                Agreement shall constitute a material breach of this Agreement.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                8. Suspension.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                Company may suspend Licensee’s use of the API and access to the Service if Company
                                believes, in its sole discretion, that Licensee has violated this Agreement, or if
                                Company believe, in its sole discretion, that it is reasonably necessary to do so to
                                protect the interests of Company or its users.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                9. Indemnification.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                Licensee agrees to defend, indemnify and hold harmless Company, its officers, directors,
                                shareholders, employees, agents, affiliates, parent, and subsidiary companies against
                                any and all costs, claims, damages or expenses incurred (and reasonable attorneys’
                                fees in connection therewith), as well as amounts finally awarded in a settlement or by
                                a court, arising from (i) Licensee’s use of the API, Content or Service in a
                                manner other than in accordance with the terms and conditions of this Agreement or the
                                Documentation (ii) Customer’s use of the App, including any use of the Service by
                                Customer that has been directed or suggested by the App or Licensee.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                10. WARRANTY DISCLAIMER; WAIVER OF CLAIMS.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                COMPANY PROVIDES THE API “AS IS” AND WITHOUT WARRANTY OF ANY KIND, AND
                                HEREBY DISCLAIMS ALL EXPRESS OR IMPLIED WARRANTIES, INCLUDING WITHOUT LIMITATION
                                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, PERFORMANCE, ACCURACY,
                                RELIABILITY, AND NON-INFRINGEMENT. COMPANY DOES NOT WARRANT THAT ACCESS TO THE SERVICES,
                                CONTENT OR API WILL BE UNINTERRUPTED OR ERROR-FREE. TO
                                THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW, LICENSEE AGREES TO WAIVE, DISCHARGE
                                CLAIMS, AND RELEASE FROM LIABILITY COMPANY, IT OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                                SUCCESSORS AND ASSIGNS FROM ANY AND ALL LIABILITY ON ACCOUNT OR, OR IN ANY WAY RESULTING
                                FROM INJURIES AND DAMAGES BASED UPON OR RELATING TO THE EXERCISE OF ANY OF ITS RIGHTS
                                GRANTED OR REFERRED TO HEREIN, EVEN IF CAUSED BY THE NEGLIGENCE OF COMPANY AND/OR ITS
                                OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, IN ANY WAY CONNECTED WITH LICENSEE’S
                                USE OR ACCESS TO THE API, SERVICES AND CONTENT. THIS
                                WAIVER AND RELEASE IS INTENDED TO BE AS BROAD AND INCLUSIVE AS PERMITTED BY LAW.
                                LICENSEE FURTHER EXPRESSLY WAIVES ALL RIGHTS CONFERRED BY SECTION 1542 OF THE CALIFORNIA
                                CIVIL CODE, OR ANY SUBSTANTIALLY SIMILAR LAW, WHICH STATES IN SUBSTANCE:
                                “A
                                GENERAL RELEASE DOES NOT EXTENT TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO
                                EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN TO HIM MUST HAVE
                                MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                11. LIMITATION OF LIABILITY.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                TO THE EXTENT PERMITTED BY APPLICABLE LAW, UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL
                                THEORY, INCLUDING, BUT NOT LIMITED TO, TORT, CONTRACT, NEGLIGENCE, STRICT LIABILITY, OR
                                OTHERWISE, SHALL COMPANY OR ITS LICENSORS, SUPPLIERS OR RESELLERS BE LIABLE TO YOU OR
                                ANY OTHER PERSON FOR ANY INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
                                INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOSS OF GOODWILL, OR DAMAGES
                                RESULTING FROM LICENSEE’S USE OF THE API. COMPANY’S LIABILITY FOR DAMAGES OF
                                ANY KIND WHATSOEVER ARISING OUT OF THIS AGREEMENT SHALL BE LIMITED TO $100. THE
                                FOREGOING WILL NOT APPLY TO DAMAGES FOR BODILY INJURY THAT, UNDER APPLICABLE LAW, CANNOT
                                BE SO LIMITED. THE FOREGOING LIMITATIONS SHALL APPLY EVEN IF YOU HAVE BEEN INFORMED OF
                                THE POSSIBILITY OF SUCH DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
                                INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSION MAY NOT APPLY
                                TO YOU.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px", fontWeight: "bold"}}>
                                12. Miscellaneous.
                            </Typography>
                            <Typography component="p" sx={{fontSize: "12px"}}>
                                This Agreement, along with Company’s standard terms of use with respect to the
                                Service (the “Terms of Use”) and the Documentation, if applicable,
                                represents the complete agreement concerning the subject matter hereof between the
                                parties and supersedes all prior agreements and representations between them. In the
                                event of any conflict between the Terms of Use this Agreement, and the
                                Documentation, such conflict shall be resolved by giving precedence in the following
                                order: (1) the Documentation, (2) this Agreement, and (3) the Terms of Use. The
                                Company reserves the right to amend this Agreement and the Documentation from time
                                to time upon notice, and if Licensee elects to continue using the Service after such
                                notice, then Licensee shall be bound by such amended terms with respect to any such
                                subsequent use. If any provision of this Agreement is held to be unenforceable for
                                any reason, such provision shall be reformed only to the extent necessary to make it
                                enforceable. The failure of Company to act with respect to a breach of this
                                Agreement by Licensee or others does not constitute a waiver and shall not limit
                                Company’s rights with respect to such breach or any subsequent breaches. This
                                Agreement is personal to Licensee and may not be assigned or transferred for any
                                reason whatsoever without Company’s consent and any action or conduct in
                                violation of the foregoing shall be void and without effect. Company expressly
                                reserves the right to assign this Agreement and to delegate any of its obligations
                                hereunder. This Agreement shall be governed by and construed under California law as
                                such law applies to agreements between California residents entered into and to be
                                performed within California. The sole and exclusive jurisdiction and venue for
                                actions arising under this Agreement shall be the State and Federal courts in San
                                Francisco, California; Licensee hereby agrees to service of process in accordance
                                with the rules of such courts. The party prevailing in any dispute under this
                                Agreement shall be entitled to its costs and legal
                                fees.
                            </Typography>
                        </Box>
                        <FormGroup sx={{mt:1}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={acceptTerms}
                                        onChange={handleChange}
                                        inputProps={{ "aria-label": "controlled" }}
                                    />
                                } label="I accept the terms and conditions."
                            />
                        </FormGroup>

                        {acceptTerms ?
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={confirmTerms}
                            >
                                Accept
                            </Button> :
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2 }}
                                disabled
                            >
                                Accept
                            </Button>}
                        <Button
                            fullWidth
                            variant="contained"
                            href="https://www.wiseconn.com/dropcontrol-platform/"
                            sx={{
                                mt: 2,
                                backgroundColor: "grey",
                                ":hover": {
                                    backgroundColor:"#3A3B3C",
                                }
                            }}
                        >
                            Decline
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};
